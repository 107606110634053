.table-row{
    cursor: pointer;
}

.table-row:hover{
    background-color: #5e72e4;
    color: white;
}

.selected-table-row{
    background-color: #5e72e4;
    color: white;
}