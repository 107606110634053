
.btn-nohover:hover{
    box-shadow: 0px 0px 0px;
}

.btn-nohover:focus{
    box-shadow: 0px 0px 0px;
}

.btn-nohover:focus-visible{
    box-shadow: 0px 0px 0px;
}

.btn-nohover:active{
    box-shadow: 0px 0px 0px;
}
